@media (max-width: 767px) {

    #media-gallery-dropdown,#documents-policies ,#student-resources-dropdown ,#hostel-campus-life-dropdown  ,#institute-links-dropdown{
       padding-bottom: 12px;
       margin-top: 12px;
       margin-left:6px;
       color: aliceblue;
    }

}
#media-gallery-dropdown:hover,#documents-policies:hover ,#student-resources-dropdown:hover ,#hostel-campus-life-dropdown:hover  ,#institute-links-dropdown:hover{
   
    background-color: rgba(0, 255, 255, 0);
    color: rgb(8, 198, 157);
  
 }

 #media-gallery-dropdown,#documents-policies ,#student-resources-dropdown ,#hostel-campus-life-dropdown  ,#institute-links-dropdown{
    font-size:18px;
    color: aliceblue;
    padding-left: 50px;
    font-weight: 500;

 }

/* Basic Styling for Cards */
.hover-card {
   transition: all 0.4s ease-out;
   transform-style: preserve-3d; /* Allow 3D transforms */
   border-radius: 8px;
   box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1); /* Subtle shadow initially */
 }
 
 /* 3D Hover Effect */
 .hover-card:hover {
   transform: translateY(-12px) rotateY(5deg) rotateX(5deg); /* Lift and rotate the card */
   box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2); /* Deeper shadow for the 3D effect */
   cursor: pointer; /* Makes it more interactive */
 }
 
 /* Subtle Tilt on Mouse Enter */
 .hover-card:active {
   transform: translateY(2px); /* Slightly reduce height when clicked */
   box-shadow: 0 12px 18px rgba(0, 0, 0, 0.15); /* Slight shadow during active state */
 }
 
 /* Smooth Shadows */
 .card {
   box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
   transition: box-shadow 0.3s ease-out; /* Smooth shadow transition */
 }
 
 .card:hover {
   box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
 }
 
 /* Icon size adjustment */
 .card i {
   transition: transform 0.3s ease;
 }
 
 /* Icon Grow on Hover */
 .card:hover i {
   transform: scale(1.1); /* Slightly enlarge the icon when hovering */
 }
 