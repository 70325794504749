/* Overall navbar styles */
.customNavbar {
 box-shadow: 0 2px 8px rgba(0, 0, 0, 0);
background: linear-gradient(-10deg , #000000 ,rgb(0, 0, 0)); /* Custom navbar background color */
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  backdrop-filter: blur(0px);
  height: 50px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.559);
}
/* rgb(14, 16, 70) */
.navbar-shadow {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.971);
}

.navbarBrand {
  font-weight: bold;
}

.navbarLogo {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.brandText {
  color: white;
  font-size: 22px;
}

.navbarLinks {
  font-size: 16px;
}

.navbarLinks .nav-link {
  padding: 12px 20px;
  color: white;
  display: flex;
  align-items: center;
  transition: transform 0.3s ease;
  border-radius: 5px;
}

.navbarLinks .nav-link:hover {
  transform: scale(1.1);
  color: #a4d65e;
}

.navbarLinks .nav-link.active {
   /* Remove background color on click */
  font-weight: normal; /* Optional: adjust font weight to normal when active */
}

/* Add margin between each NavDropdown */
.navDropdown {
  margin-right: 15px; /* Adjust this value as needed */
}

.navDropdown .dropdown-menu {
  padding: 10px 0; /* Adding space between items */
  border-radius: 8px;
  border: 1px solid #ddd;
}

.navDropdown .dropdown-item {
  padding: 10px 20px;
  color: #333;
  transition: background-color 0.2s ease;
}

.navDropdown .dropdown-item:hover {
  background-color: #00c896;
  color: white;
}

.navDropdown .dropdown-toggle::after {
  display: none; /* Remove default dropdown arrow */
}

.navDropdown .dropdown-item + .dropdown-item {
  margin-top: 8px; /* Space between items */
}

.navDropdown .dropdown-item:active {
  background-color: #006747;
}

/* Mobile styles for navbar toggle */
@media (max-width: 767px) {
 
  .navbarLinks .nav-link {
    padding: 10px 15px;
    font-size: 14px;
  }
}
/* Overall dropdown menu styles */
.navDropdown .dropdown-menu {
  min-width: 220px; /* Wider dropdown */
  padding: 0; /* Remove unnecessary padding */
  border-radius: 8px; /* Rounded corners for dropdown */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Soft shadow for better visibility */
  background-color: #ffffff; /* White background for dropdown */
  border: none; /* Remove border */
}

/* Dropdown items styling */
.navDropdown .dropdown-item {
  padding: 12px 20px; /* Standard padding for better clickability */
  font-size: 16px; /* Standard font size */
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* Standard font */
  color: #333; /* Dark text color for readability */
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth hover effect */
  border-radius: 5px; /* Rounded item corners */
  text-transform: none; /* Prevent text from being transformed */
}

.navDropdown .dropdown-item:hover {
  background-color: #00c896; /* Green hover effect */
  color: white; /* White text color on hover */
}

/* Adjusting the dropdown toggle button */
.navDropdown .dropdown-toggle {
  font-size: 16px; /* Standard font size */
  padding: 12px 20px; /* Padding for the toggle */
  color: #333; /* Default color for toggle */
  border-radius: 5px;
  background-color: transparent; /* Transparent background */
  border: none; /* Remove border */
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* Standard font */
  transition: background-color 0.3s ease; /* Smooth transition on hover */
}

/* Dropdown toggle hover effect */
.navDropdown .dropdown-toggle:hover {
  background-color: #f4f4f4; /* Light background on hover */
  color: #00c896; /* Green text on hover */
}

/* Space between dropdown items */
.navDropdown .dropdown-item + .dropdown-item {
  margin-top: 8px; /* Padding between items */
}

/* Mobile view */
@media (max-width: 767px) {
  /* Hide the dropdown toggle button in mobile view */
 
  .navDropdown .dropdown-toggle {
    display: none; /* Hide toggle */
  }

  /* Ensure dropdown items appear in a vertical list as normal links */
  .navDropdown .dropdown-item {
    display: block;
    font-size: 14px; /* Smaller font size on mobile */
    padding: 10px 20px; /* Reduced padding for mobile */
    color: #333; /* Dark text color */
    background-color: transparent; /* Transparent background */
    text-align: left; /* Align items to the left */
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* Consistent font */
    border: none; /* No borders on mobile */
    border-radius: 5px; /* Rounded corners for each item */
    transition: background-color 0.3s ease; /* Smooth hover effect */
  }

  /* Adjust hover effect for mobile view */
  .navDropdown .dropdown-item:hover {
    background-color: #000000; /* Green background on hover */
    color: white; /* White text on hover */
    
  }

  /* Add spacing between items on mobile */
  .navDropdown .dropdown-item + .dropdown-item {
    margin-top: 10px;
  }
}
