.customNavbar {
    background: #000000; /* Custom navbar background color */
    color: white; /* Text color */
    padding: 0.5rem 1rem; /* Padding for the navbar */
    transition: all 0.3s ease-in-out; /* Smooth transition for background/scroll effect */
  }
  
  .navbarShadow {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Shadow effect when scrolling */
  }
  
  .navDropdown {
    background-color: #f8f9fa; /* Light background color for dropdowns */
    border-radius: 4px; /* Rounded corners */
    color: rgba(0, 0, 0, 0.916); /* Text color */
  }
  
  .navDropdown .dropdown-menu {
    min-width: 200px; /* Ensures dropdown has a minimum width */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Adds shadow around dropdown */
    z-index: 1050; /* Ensures dropdown appears on top */
  }
  
  .navDropdown .dropdown-item {
    padding: 10px 15px; /* Padding inside each dropdown item */
    transition: background-color 0.3s ease-in-out; /* Smooth hover effect */
  }
  
  .navDropdown .dropdown-item:hover {
    background-color: #f1f1f1; /* Hover color */
    color: #007bff; /* Text color on hover */
  }
  
  .navDropdown .dropdown-divider {
    margin: 5px 0; /* Space between items and dividers */
  }
  
  .navbar-toggler {
    border: none; /* No border for the toggle button */
  }
  
  .navbar-toggler-icon {
    background-color: white; /* Toggle icon color */
  }
  
  .navbar-nav .nav-link {
    font-size: 1rem; /* Font size for the navbar links */
    color: white; /* Default text color */
    padding: 0.5rem 1rem; /* Padding around each link */
    display: flex;
    align-items: center;
    transition: color 0.3s ease-in-out;
  }
  
  .navbar-nav .nav-link:hover {
    color: #007bff; /* Hover effect for navbar links */
  }
  
  .material-icons {
    font-size: 2rem; /* Custom size for material icons */
  }
  
  .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
  
  /* Media Queries */
  @media (max-width: 991px) {
    .customNavbar {
      padding: 0.5rem; /* Adjust padding for smaller screens */
    }
  
    .navbar-nav .nav-link {
      font-size: 0.9rem; /* Slightly smaller font size for mobile */
    }
  }
  