.container {
    max-width: 1200px; /* Increased max-width for better layout on larger screens */
    margin: 20px auto 0; /* Set top margin to 20px and center the container */
    animation: fadeIn 0.5s ease-in-out; /* Animation for fading in */
    padding-top: 30px;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px); /* Slide effect */
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  html {
    touch-action: manipulation; /* Prevents zooming on touch devices */
  }
  
  body {
    overscroll-behavior: contain; /* Prevents the scroll from being zoomed */

  }
  /* Hide the scrollbar while keeping the content scrollable */
.no-scrollbar::-webkit-scrollbar {
  display: none;  /* Hides the scrollbar in WebKit browsers */
}

.no-scrollbar {
  -ms-overflow-style: none;  /* Hides scrollbar in Internet Explorer and Edge */
  scrollbar-width: none;  /* Hides scrollbar in Firefox */
}
body.inverted {
  filter: invert(1) hue-rotate(180deg);
}

/* Exclude Images in Dark Mode */
body.inverted img {
  filter: invert(0) !important;
}


@media (max-width: 768px) {
  .book-names-section {
    width: 100%;
  }
  .book-names-header h5 {
    font-size: 1rem;  /* Smaller text size for mobile */
  }
  .book-names-scroll h5 {
    font-size: 1rem;  /* Adjust text size for mobile */
  }
}


.book-carousel-container {
  width: 100%;
  overflow: hidden; /* Hide the overflow of the scroll */
}

.carousel-inner {
  display: flex;
  flex-wrap: nowrap;
}

.carousel-item {
  flex-shrink: 0;
  margin-right: 10px; /* Space between items */
}

.carousel-inner {
  animation: scroll-left 40s linear infinite; /* Continuous scroll */
}
#hide{
  display: block;
  
}
#mob{
  display: none;
}
.book-names-section{
  width: 20%;
}
#pc{
  display:block;
}
#head{
  display: none;
}
#data{
  display: none;
}
@media (max-width: 768px) {
#hide{
  display: none;
  
}
#head{
  /* background-color: rgb(14, 16, 70); */
  display: block;
}
#mob{
  display: block;
  
}
#data{
  display: block;
}
#pc{
  display:none;
}
.book-names-section{
  width:50%;
}
#bookCarousel{
  width: 100%;
}
#loginGap{
  margin-top: -40px;
  margin-bottom:20px;
}
}


/* Hide arrow by default */
.arrow {
  opacity: 0;
  transition: opacity 0.3s ease;
}

/* Change text color and show arrow on hover */
.link-hover:hover {
  color: #0056b3; /* Change text color on hover */
 
}

.link-hover:hover .arrow {
  opacity: 1; /* Show the arrow when hovered */
  transform: translateX(5px); /* Slight movement of the arrow on hover */
}

.link-hover i {
  transition: transform 0.3s ease;
}

/* Optional: Enlarge the icons on hover */
.link-hover:hover i {
  transform: scale(1.1); /* Slightly enlarge the icon on hover */
}

@keyframes scrollNotice {
  0% {
    transform: translateX(100%); /* Start off-screen to the right */
  }
  100% {
    transform: translateX(-100%); /* Move off-screen to the left */
  }
}

.star-rating {
  display: flex;
  font-size: 24px;
  color: #ddd;
}

.star {
  cursor: pointer;
}

.filled {
  color: #ffc107; /* Yellow color for filled stars */
}

.uploading-icon {
  animation: rotate 1s infinite linear;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.highlight {
  background-color: yellow;
  color: black;
}
.image-container {
  position: relative;
  overflow: hidden; /* Ensure elements do not overflow the container */
  border-radius: 5px; /* Match the image border radius */
}

.image-container img {
  display: block;
  transition: transform 0.5s ease-in-out, filter 0.5s ease-in-out;
}

.image-container:hover img {
  transform: scale(1.1); /* Add more zoom */
  filter: brightness(0.8); /* Slightly darken the image for contrast */
}

.image-container .hover-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.214); /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  backdrop-filter: blur(3px); /* Stronger blur effect */
  color: #fff;
  font-size: 1.5rem;
  text-align: center;
  border-radius: 3px;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  transform: translateY(10px); /* Initial offset for sliding effect */
}

.image-container:hover .hover-overlay {
  opacity: 1; /* Show overlay on hover */
  transform: translateY(0); /* Slide into position */
}

.hover-text {
  animation: text-fade-in 1s ease-in-out forwards; /* Text-specific animation */
  transform: translateY(70px); /* Initial position for animation */
  opacity: 0; /* Initially hidden */
}

@keyframes text-fade-in {
  0% {
    opacity: 0;
    transform: translateY(20px); /* Start below the overlay */
  }
  100% {
    opacity: 1;
    transform: translateY(0); /* Settle into final position */
  }
}
.top-slide-animation {
  display: inline-block;
  opacity: 0;
  transform: translateY(-40px); /* Start above the viewport */
  animation: slideFromTop 1s ease-out forwards;
}

@keyframes slideFromTop {
  0% {
    opacity: 0;
    transform: translateY(-40px); /* Starting position above */
  }
  60% {
    opacity: 0.8;
    transform: translateY(10px); /* Slight overshoot for smooth effect */
  }
  100% {
    opacity: 1;
    transform: translateY(0); /* Settle into final position */
  }
}
.card:hover {
  transform: scale(1.001);
  box-shadow: 0 8px 40px rgba(0, 0, 0, 0.15);
}
.hover-effect:hover {
  background-color: #e2e6ea;
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}
